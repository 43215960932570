import { CliftonStrengthOptionsMap } from "../Constants/CliftonStrengths";
import Sectors from '../Constants/Sector.json';

export function formatRenumerationType(value) {
    if (value === 861400000) return "per year";
    else if (value === 861400001) return "per hour";
}


export function formatCliftonStrengths(value) {
    if(typeof value === "string") {
        return value.split(",").filter(a => a && !isNaN(a)).map(val =>
            (CliftonStrengthOptionsMap[Number(val)] || CliftonStrengthOptionsMap[val])?.label
        ).join(",");
    } else {
        return CliftonStrengthOptionsMap[value]?.label;
    }
}

export function formatSectors(value) {
    if(typeof value === "string") {
        return value.split(",").filter(a => a && !isNaN(a)).map(val =>
            Sectors.find(s => s.value === Number(value))?.label
        ).join(",");
    } else {
        return Sectors.find(s => s.value === value)?.label
    }

}