import { formatCliftonStrengths } from '../../Functions/FormattedValues';
import CreateTableContext from './TableContext';

const fields = [
    "mcb_abcid",
    "mcb_achievement",
    "mcb_benefit",
    "mcb_roletitle",
    "mcb_companyname",
    "mcb_sector",
    "mcb_skillssummary",
    "mcb_achievementstatement",
    "mcb_cliftonstrengthsummary",
    "mcb_customstrengthsummary",
    "mcb_cliftonstrengths",
    "mcb_index"
];

const relations = {
    "mcb_Person": "contact",
    "mcb_Role": "mcb_role",
    "mcb_Responsibility": "mcb_responsibility",
};

const expand = {
    "mcb_ABCResponsibility" : "mcb_responsibility",
    "mcb_ABCSkill": "mcb_skill",
    "mcb_CVABC": "mcb_cv"
};

const readOnly = [
    "modifiedon"
];

const formattedValues = {
    mcb_cliftonstrengths: formatCliftonStrengths,
};


const [useABC, ABCProvider] = CreateTableContext(
    "mcb_abc", {
        contextName: "ABC",
        preSubmission: (entity, profileData) => ({
            ...entity,
            _mcb_person_value : profileData?.id
        }), fields, relations, readOnly, expand, formattedValues
    }
);

export default useABC;
export { useABC, ABCProvider };
