import React from 'react';
import { addClassNames } from '../../Functions/Helper/Component';
import "./Icons.scss";
function ToIcon(icon) {
    return React.forwardRef(({className, ...props}, ref) => (
        <i {...props} ref={ref} className={addClassNames(className, `bi bi-${icon}`)}></i>
    ));
}


export const XCircle = ToIcon("x-circle");
export const Robot = ToIcon("robot");
export const FloppyFill = ToIcon("floppy-fill");
export const PlusCircle = ToIcon("plus-circle");
export const PencilFill = ToIcon("pencil-fill");
export const PencilSquare = ToIcon("pencil-square");
export const Trophy = ToIcon("trophy");
export const ThreeDotsVertical = ToIcon("three-dots-vertical");
export const ThreeDotsHorizontal = ToIcon("three-dots");
export const ThreeDotsResponsive = ToIcon("three-dots-vertical responsive");
export const CheckCircle = ToIcon("check-circle");
export const ArrowLeft = ToIcon("arrow-left");
export const CloudDownload = ToIcon("cloud-download");
export const Download = ToIcon("download");
export const TrashFill = ToIcon("trash-fill");
export const Upload = ToIcon("upload");
export const Briefcase = ToIcon("briefcase");
export const RocketTakeoffFill = ToIcon("rocket-takeoff-fill");
export const Person = ToIcon("person");
export const X = ToIcon("x");
export const RocketTakeoff = ToIcon("rocket-takeoff");
export const ClipboardCheck = ToIcon("clipboard-check");
export const ClipboardHeart = ToIcon("clipboard-heart");
export const TrophyFill = ToIcon("trophy-fill");
export const DashCircle = ToIcon("dash-circle");
export const Search = ToIcon("search");
export const ChevronDown = ToIcon("chevron-down");
export const GraphUp = ToIcon("graph-up");
export const FileEarmarkText = ToIcon("file-earmark-text");
export const ColumnsGap = ToIcon("columns-gap");
export const ArrowUp = ToIcon("arrow-up");
export const ArrowDown = ToIcon("arrow-down");
export const Envelope = ToIcon("envelope");
export const Archive = ToIcon("archive");
export const Copy = ToIcon("copy");
export const PlusCircleFill = ToIcon("plus-circle-fill");
export const InfoCircleFill = ToIcon("info-circle-fill");
export const GripVertical = ToIcon("grip-vertical");
export const GripHorizontal = ToIcon("grip-horizontal");
export const GripResponsive = ToIcon("grip-vertical responsive");
export const ChevronLeft = ToIcon("chevron-left");
export const ChevronRight = ToIcon("chevron-right");
export const HandIndexThumbFill = ToIcon("hand-index-thumb-fill");
export const ChevronDoubleLeft = ToIcon("chevron-double-left");
export const ChevronDoubleRight = ToIcon("chevron-double-right");
export const DashCircleFill = ToIcon("dash-circle-fill");
export const ArrowsMove = ToIcon("arrows-move");
export const EnvelopeAtFill = ToIcon("envelope-at-fill");
export const EnvelopePaper = ToIcon("envelope-paper");
export const BoxArrowInRight = ToIcon("box-arrow-in-right");
export const ZoomIn = ToIcon("zoom-in");
export const ZoomOut = ToIcon("zoom-out");
export const BriefcaseFill = ToIcon("briefcase-fill");